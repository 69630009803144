import React from "react";
import Button from "../../designSystem/button/Button";
import Title from "../../designSystem/title/Title";
import "./style.css";

export default function Cta({ border = false, background = false }) {
  return (
    <section
      id="cta"
      className={`anim-to-scroll ${border ? "border" : ""} ${
        background ? "background" : ""
      }`}
    >
      <div className="cta-container">
        <div className="appear appear-2">
          <Title>
            <h3>
              Travaillons ensemble.
              <br />
              <span className="golden-color">
                Je vous réponds dans les 24 heures !
              </span>
            </h3>
          </Title>
        </div>
        <div className="appear appear-2">
          <Button
            link="https://calendly.com/adam-makhloufi-pro/30min?month=2023-01"
            title="Prendre rendez-vous"
            anchor={true}
            target="_blank"
            arrow={true}
          />
        </div>
      </div>
    </section>
  );
}
