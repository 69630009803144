import React from "react";
import Seo from "../../components/Seo";
import HeaderShow from "../../components/HeaderShow/HeaderShow";
import Button from "../../components/designSystem/button/Button";
import "./style.css";

export default function Meet() {
  return (
    <>
      <Seo title="Prendre rendez-vous" />
      {/* <HeaderShow>Prendre rendez-vous</HeaderShow> */}
      <main>
        <section className="meet centered-elements">
          <h2 className="meet__title">
            Si vous souhaitez avoir plus d'informations sur mes services,
            prenons le temps d'en discuter lors d'un appel téléphonique !
          </h2>
          <Button
            title="Selectionner une date de rendez-vous"
            type="secondary"
            margin={false}
            anchor={true}
            link="https://calendly.com/adam-makhloufi-pro/30min?month=2023-01"
          />
        </section>
      </main>
    </>
  );
}
