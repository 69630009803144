import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useState } from "react";
import Button from "../designSystem/button/Button";
import "./style.css";
import logo from "./img/logo.png";
import { pages } from "../../data/pages";

export default function Navbar() {
  const [navbar, setNavbar] = useState(false);
  const [menuMobile, setMenuMobile] = useState(false);

  const activeNavbar = () => {
    setNavbar(false);

    if (window.scrollY >= 30) {
      setNavbar(true);
    }
  };

  window.addEventListener("scroll", activeNavbar);

  useEffect(() => {
    document.body.style.overflow = "visible";
    if (menuMobile) {
      document.body.style.overflow = "hidden";
    }
  }, [menuMobile]);

  return (
    <>
      <div
        className={`${navbar ? "navbar-container active" : "navbar-container"}`}
      >
        <div className="navbar">
          <div className="logo">
            <Link to={"/"}>
              <img className="logo-img" src={logo} alt="Logo MK prod" />
            </Link>
          </div>
          <nav className="link">
            <ul>
              {pages.map((item, key) => {
                return (
                  <li key={key}>
                    <NavLink className="link-hover" end to={item.link}>
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>
          <div className="cta">
            <Button
              title="Prendre rendez-vous"
              type="primary no-border"
              arrow={true}
              margin={false}
              anchor={true}
              target="_blank"
              link="https://calendly.com/adam-makhloufi-pro/30min?month=2023-01"
            />
          </div>

          <div className="menu-mobile">
            <div
              onClick={() =>
                menuMobile ? setMenuMobile(false) : setMenuMobile(true)
              }
              className={`menu-burger ${menuMobile ? "cross" : ""}`}
            ></div>

            <div className={`menu-items ${menuMobile ? "active" : ""}`}>
              <nav>
                <ul>
                  {pages.map((item, key) => {
                    return (
                      <li key={key}>
                        <NavLink
                          onClick={() => setMenuMobile(false)}
                          className="link-hover"
                          end
                          to={item.link}
                        >
                          {item.title}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
