import React from "react";
import Title from "../../designSystem/title/Title";
import { details } from "../../../data/details";
import "./style.css";

export default function Details() {
  console.log(details);

  return (
    <section className="details anim-to-scroll">
      <Title centered={true}>
        <h3 className="details__title golden-color appear appear-1">À quoi s'attendre</h3>
      </Title>
      <div className="details__container">
        {details.map((detailsBox, key) => {
          return (
            <div key={key} className="details__box">
              {detailsBox.column.map((item, key) => {
                return (
                  <div key={key} className={`details__box-item ${item.classname}`}>
                    <h4 className="details__box-title">{item.title}</h4>
                    <p>{item.descr}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </section>
  );
}
