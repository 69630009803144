module.exports = {
  pages: [
    {
      title: "Prestation",
      link: "/",
      end: "true",
    },
    {
      title: "Tarifications",
      link: "/pricing",
    },
    // {
    //   title: "Photos",
    //   link: "/photos",
    // },
    // {
    //   title: "Vidéos",
    //   link: "/videos",
    // },
    {
      title: "FAQ",
      link: "/faq",
    },
  ],
};
