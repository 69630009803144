import React from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation, useRoutes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/home/home";
import Photos from "./pages/photos/photos";
import Videos from "./pages/videos/videos";
import "./style/general.css";
import Scroll from "./components/Scroll";
import NotFound from "./pages/notFound/NotFound";
import Meet from "./pages/meet/Meet";
import Faq from "./pages/faq/faq";
import Pricing from "./pages/pricing/Pricing";

export default function App() {
  return (
    <>
      <Scroll>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/meet" element={<Meet />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Scroll>
    </>
  );
}
