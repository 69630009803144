module.exports = {
  about: [
    {
      descr: `Félicitations pour vos fiançailles! Je suis ravi de vous accueillir chez Adam Wedding, votre source de souvenirs inoubliables.`,
    },
    {
      descr: `Je comprends que les préparatifs pour votre mariage peuvent être stressants, mais je suis là pour vous faciliter la vie. Mon objectif est de capturer les moments les plus importants de votre journée afin que vous puissiez revivre ces moments précieux pour toujours.`,
    },
    {
      descr: `Je suis ravi de vous accompagner dans ce moment important de votre vie et j'espère pouvoir vous aider à créer des souvenirs qui dureront toute une vie.`,
    },
  ],
};
