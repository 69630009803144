import React from "react";
import Chip from "../../designSystem/chip/Chip";
import Title from "../../designSystem/title/Title";
import "./style.css";
import Box from "../../designSystem/box/Box";
import Button from "../../designSystem/button/Button";
import { services } from "../../../data/services";

export default function Services() {
  return (
    <section id="services" className="anim-to-scroll">
      <div className="services-container centered-elements">
        <div className="appear appear-1">
          <Chip title={"Services"} centered={true} inverted={true} />
          <Title centered={true}>
            <h3 className="golden-color">Votre mariage en image</h3>
          </Title>
        </div>

        <div className="box-container">
          {services.map((item, key) => {
            return (
              <Box
                key={key}
                link={item.link}
                classname={item.classname}
                title={item.title}
                video={item.video}
              />
            );
          })}
        </div>
        <div className="appear appear-1">
          <Button
            title="Voir les tarifications"
            type="primary"
            arrow="true"
            centered="true"
            link="./pricing"
          />
        </div>
      </div>
    </section>
  );
}
