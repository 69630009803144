import React from "react";
import { useState } from "react";
import Filter from "../../components/designSystem/filter/Filter";
import Seo from "../../components/Seo";
import HeaderShow from "../../components/HeaderShow/HeaderShow";
import "./style.css";
import { types, photos } from "../../data/photos";

export default function Photos() {
  const [dataPhotos, setDataPhotos] = useState(photos);
  const [titlePage, setTitlePage] = useState("Services photos");
  const [loading, setLoading] = useState(true);
  return (
    <>
      <Seo title="Mes photos" />
      <HeaderShow classname="photos" title="services-photos">
        Services photos
      </HeaderShow>
      <main>
        <div className={loading ? "loading" : "loaded"}>
          <div className="loader"></div>
        </div>
        <Filter
          data={photos}
          setter={setDataPhotos}
          setTitlePage={setTitlePage}
          loading={loading}
          setLoading={setLoading}
          type={types}
        />

        <section className="centered-elements section-photos-container">
          {dataPhotos.map((photo, index) => {
            return (
              <div key={index} className="anim-to-scroll">
                {photo.title && (
                  <div className="title-company-container">
                    <div className="title-company">
                      <h2>{photo.title}</h2>
                    </div>
                  </div>
                )}

                <div
                  className={`photo-container ${
                    photo.format ? photo.format : ""
                  } ${index > 1 ? "appear appear-1" : ""} ${photo.type}`}
                >
                  <img
                    className="picture"
                    src={photo.img}
                    alt={photo.alt}
                    style={{
                      objectPosition: photo.objectPosition,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </section>
      </main>
    </>
  );
}
