import React from "react";
import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import imagesLoaded from "imagesloaded";
import "./style.css";

export default function Filter({
  data,
  setter,
  type,
  setTitlePage,
  loading,
  setLoading,
  animation = true,
}) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const typeParams = params.get("type");
  const pageNameParams = params.get("pageName");
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  useEffect(() => {
    console.log(loading)
    if (loading) {
      document.body.style.overflow = "hidden";

      const preloadImages = (selector = "img") => {
        return new Promise((resolve) => {
          let elmt = document.querySelectorAll(selector);
          imagesLoaded(elmt, { background: true }, resolve);
        });
      };

      setTimeout(() => {
        preloadImages(".picture, .header-show").then((_) => {
          setLoading(false);
          document.body.style.overflow = "visible";
        });
      }, "800");
    }
  }, [loading, setLoading]);

  useEffect(() => {
    async function typeFilter() {
      // Animation
      const allFilter = document.getElementsByClassName("filter-btn");
      for (let elt of allFilter) {
        elt.classList.remove("active");
      }
      const filterActive = document.getElementsByClassName(
        `filter-btn ${typeParams}`
      );
      for (let elt of filterActive) {
        elt.classList.add("active");
      }

      const animationFilter = async (event) => {
        const sectionPhotos = document.querySelectorAll(
          ".section-photos-container"
        );
        for (let elt of sectionPhotos) {
          elt.classList.remove("filter-active");
          elt.classList.add("filter-delete");
        }
        animation && (await delay(800));
        for (let elt of sectionPhotos) {
          elt.classList.remove("filter-delete");
          elt.classList.add("filter-active");
        }
      };
      const result = data.filter((item) => {
        if (typeParams === null || typeParams === "all") {
          setTitlePage("Services photos");
          return item;
        }
        setTitlePage(pageNameParams);
        return item.type === typeParams;
      });
      animationFilter();
      animation && (await delay(450));
      setter(result);
    }
    typeFilter();
  }, [data, typeParams, pageNameParams, setter, setTitlePage, animation]);

  return (
    <ul className="filter">
      <li>
        <Link className="filter-btn all active" to={"?type=all"}>
          Tout
        </Link>
      </li>
      {type.map((item, index) => {
        const type = item.type;
        return (
          <li key={index}>
            <Link
              className={`filter-btn ${type}`}
              to={`?type=${type}&pageName=${item.text}`}
            >
              {item.text}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
