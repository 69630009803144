import React from "react";
import "./style.css";

const Chip = ({ title, centered = "", inverted = "" }) => {
  return (
    <div className={`chip-container${centered && " centered"}`}>
      <div className={`chip${inverted && " inverted"}`}>
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default Chip;
