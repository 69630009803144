module.exports = {
  types: [
    {
      type: "wedding",
      text: "Mariage",
    },
    {
      type: "event",
      text: "Événement",
    },
  ],
  videos: [
    {
      id: "1xzQWlC2lL8",
      title: "Mariage de Jordan et Agathe",
      type: "wedding"
    },
    {
      id: "_190YCfR_h4",
      title: "Homies - Club foot américain",
      type: "event"
    },
  ],
};
