import React from "react";
import Button from "../../designSystem/button/Button";
import "./style.css";

export default function Header() {
  return (
    <header className="header-home">
      <div className="header-section">
        <div className="header-container">
          <h1 className="big-title">
            <span className="word">Votre&nbsp;</span>
            <span className="word">plus&nbsp;</span>
            <span className="word">beau&nbsp;</span>
            <span className="word">jour&nbsp;</span>
            <span className="word">en&nbsp;</span>
            <span className="word">image</span>
          </h1>
          <div className="button-container button-animation">
            <Button
              title="Mes services"
              type="secondary"
              anchor="true"
              link="#services"
            />
          </div>
        </div>
      </div>
    </header>
  );
}
