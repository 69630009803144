module.exports = {
  details: [
    {
      column: [
        {
          title: "Préparation de la journée",
          descr: "Il est important que vous nous contactiez le plus tot possible ! Nous mettons un point d'honneur à garder une bonne organisation afin que vous puissiez profiter de votre jour J.",
          classname: "appear appear-1"
        },
        {
          title: "Payement",
          descr: "Un dépôt non-remboursable de 30 % est requis pour garantir votre date. Les 70 % restants de votre solde sont dus deux semaines après votre mariage.",
          classname: "appear appear-3"
        },
      ],
    },

    {
      column: [
        {
          title: "Programmation",
          descr: "Les mariages sont prévus de 6 à 12 mois à l'avance. Les annulations et les reports doivent être effectués par courriel ou par téléphone au moins 48 heures avant votre séance. Tous les dépôts de cautions sont non remboursables.",
          classname: "appear appear-2"
        },
        {
          title: "Garde-robe",
          descr: "Portez quelque chose qui vous fait vous sentir à l'aise et confiant ! Motifs, couleurs audacieuses, et accessoires photos vraiment bien. N'hésitez pas à demander de l'aide !",
          classname: "appear appear-4"
        },
      ],
    },
  ],
};
