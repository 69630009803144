import React from "react";
import "./style.css";

export default function HeaderShow({
  classname = "",
  title = "",
  children,
}) {
  return (
    <header className={`header-show ${classname} ${title}`}>
      <div className="header-show-section">
        <div className="header-show-container">
          <h1 className="big-title">{children}</h1>
        </div>
      </div>
    </header>
  );
}
