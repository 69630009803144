import React from "react";
import "./style.css";

export default function Box({ classname, title, video = "" }) {
  return (
    <div className={`box ${classname}`}>
      {title && <h2 className="box-title golden-color">{title}</h2>}
      {video && (
        <iframe
          className="box__video"
          src="https://www.youtube.com/embed/fyftU-oKibc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      )}
    </div>
  );
}
