module.exports = {
  types: [
    {
      type: "portrait",
      text: "Portrait",
    },
    {
      type: "wedding",
      text: "Mariage",
    },
    {
      type: "event",
      text: "Événement",
    },
    {
      type: "product",
      text: "Produit",
    },
    {
      type: "food",
      text: "Culinaire",
    },
  ],
  photos: [
    {
      img: "img/portrait/1.jpg",
      objectPosition: "center top",
      alt: "alt",
      type: "portrait",
    },
    {
      img: "img/portrait/2.jpg",
      alt: "alt",
      type: "portrait",
    },
    {
      objectPosition: "top",
      img: "img/portrait/3.jpg",
      alt: "alt",
      type: "portrait",
    },
    {
      objectPosition: "100% 30%",
      img: "img/portrait/4.jpg",
      alt: "alt",
      type: "portrait",
    },
    {
      objectPosition: "top",
      img: "img/portrait/5.jpg",
      alt: "alt",
      type: "portrait",
    },
    {
      img: "img/portrait/6.jpg",
      alt: "alt",
      type: "portrait",
    },
    {
      img: "img/portrait/7.jpg",
      objectPosition: "top",
      alt: "alt",
      type: "portrait",
    },
    {
      objectPosition: "top",
      img: "img/portrait/8.jpg",
      alt: "alt",
      type: "portrait",
    },
    {
      img: "img/portrait/9.jpg",
      alt: "alt",
      type: "portrait",
    },
    {
      objectPosition: "top",
      img: "img/portrait/10.jpg",
      alt: "alt",
      type: "portrait",
    },
    {
      img: "img/wedding/1.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/2.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/3.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/5.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/6.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/4.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/7.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/8.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/9.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/10.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/11.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/12.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/13.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/14.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/15.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/16.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/17.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/18.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/19.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/20.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/21.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/22.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/wedding/23.jpg",
      alt: "alt",
      type: "wedding",
    },
    {
      img: "img/event/2.webp",
      alt: "alt",
      type: "event",
    },

    {
      img: "img/event/3.webp",
      alt: "alt",
      type: "event",
    },
    {
      objectPosition: "100% 35%",
      img: "img/event/1.webp",
      alt: "alt",
      type: "event",
    },
    {
      img: "img/event/4.webp",
      alt: "alt",
      type: "event",
    },
    {
      img: "img/event/5.webp",
      alt: "alt",
      type: "event",
    },
    {
      img: "img/event/6.webp",
      alt: "alt",
      type: "event",
    },
    {
      img: "img/event/7.webp",
      alt: "alt",
      type: "event",
      format: "landscape",
    },
    {
      img: "img/product/product1.png",
      alt: "alt",
      type: "product",
    },
    {
      img: "img/product/product2.jpg",
      alt: "alt",
      type: "product",
    },
    {
      img: "img/product/product3.jpg",
      alt: "alt",
      type: "product",
    },
    {
      img: "img/product/product4.jpg",
      alt: "alt",
      type: "product",
    },

    {
      objectPosition: "100% 40%",
      img: "img/product/product5.jpg",
      alt: "alt",
      type: "product",
    },
    {
      img: "img/food/food3.jpg",
      alt: "alt",
      type: "food",
    },
    {
      img: "img/food/food1.jpg",
      alt: "alt",
      type: "food",
    },
    {
      img: "img/food/food7.jpg",
      alt: "alt",
      type: "food",
    },
    {
      img: "img/food/food4.jpg",
      alt: "alt",
      type: "food",
    },
    {
      img: "img/food/food2.jpg",
      alt: "alt",
      type: "food",
    },
    {
      img: "img/food/food6.jpg",
      alt: "alt",
      type: "food",
    },
    {
      img: "img/food/food9.jpg",
      alt: "alt",
      type: "food",
    },
    {
      img: "img/food/food5.jpg",
      alt: "alt",
      type: "food",
    },
  ],
};
