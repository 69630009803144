module.exports = {
  reviews: [
    {
      text: `Dynamique, ponctuel, et professionnel, Adam est un photographe passionné qui ira loin.
      Nous avons fait appel à lui pour deux missions pour des évènements à Paris, et le brief a été respecté à la lettre, et notre client a été très content du rendu de ses images.
      Je ne peux que recommander. Bravo et merci Adam. Foncez sans hésiter.`,
      author: "Aurélien Guiné - Edison Production",
    },
  ],
};
