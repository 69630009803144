import React from "react";
import { Helmet } from "react-helmet";

import {
  baseTitle,
  defaultDescription,
  defaultKeywords,
} from "../data/config";

const Seo = ({ description, title, keywords }) => {
  return (
    <Helmet>
      <html lang="fr" />
      <title>{title} {baseTitle}</title>
      <meta
        name="description"
        content={description ? description : defaultDescription}
      />
      <meta
        name="keywords"
        content={keywords ? keywords : defaultKeywords}
        data-react-helmet="true"
      />
    </Helmet>
  );
};

export default Seo;
