import React from "react";
import { useLocation } from "react-router-dom";
import "./style.css";

export default function NotFound() {
  const sampleLocation = useLocation();
  const pageName = sampleLocation.pathname.replace("/", "");
  return (
    <div className="error404 golden-color">
      <span>
        La page "{pageName}" <br /> est introuvable 😵
      </span>
    </div>
  );
}
