module.exports = {
  questionsResponses: [
    {
      question: "Combien de temps à l'avance dois-je vous contacter ?",
      response: "Le plus tôt possible.",
    },
    {
      question: "Pouvez-vous vous déplacer ?",
      response: "Jusqu'à 60 kilomètres autour de Paris.",
    },
    {
      question: "Facturez-vous les déplacements ?",
      response: "Ils sont inclus dans la prestation.",
    },
    {
      question: "Quel type de matériel utilisez-vous ?",
      response: "Caméra 4K, stabilisateur, trépied, drone.",
    },
    {
      question:
        "Quel est le temps approximatif de livraison de la vidéo finale ?",
      response: "Sous 14 jours maximum :)",
    },
    {
      question:
        "Si besoin est, accepteriez-vous de faire des heures supplémentaires ?",
      response: "Oui bien sûr.",
    },
    {
      question:
        "Photoshoperez vous mes images pour cacher les taches, les rides, etc. indésirables ?",
      response:
        "Non ! Le but n'est pas de poser pour un magasin, vous êtes comme vous êtes et c'est très bien comme ça :). Le but des retouches photos est de garder une constance dans la couleur, teinte, style de l'image. ",
    },
  ],
};
