import React from "react";
import HeaderShow from "../../components/HeaderShow/HeaderShow";
import Seo from "../../components/Seo";
import "./style.css";
import { questionsResponses } from "../../data/questionsResponses";

export default function Faq() {
  return (
    <>
      <Seo title="Questions fréquentes" />

      <HeaderShow>Questions fréquentes</HeaderShow>
      <main>
        <section className="faq centered-elements">
          <ul className="faq__container">
            {questionsResponses.map((item, key) => {
              return (
                <li key={key} className="faq__item">
                  <div className="faq__question">
                    <h2>{item.question}</h2>
                  </div>
                  <div className="faq__response">
                    <p>{item.response}</p>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="faq__image"></div>
        </section>
      </main>
    </>
  );
}
