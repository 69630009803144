module.exports = {
  prestations: [
    {
      title: "Offre Photo",
      icon: "📷",
      price: "750€ (TTC)",
      details: [
        {
          info: "Préparations des mariés à la pièce montée",
        },
        {
          info: "300 à 400 photos",
        },
        {
          info: "Qualité HD ",
        },
        {
          info: "Rendu sous 14 jours",
        },
        {
          info: "Disponible sur galerie en ligne",
        },
        {
          info: "Album photo",
          style: {
            marginTop: "40px",
          },
        },
      ],
    },
    {
      title: "Offre Vidéo",
      icon: "🎞️",
      price: "850€ (TTC)",
      details: [
        {
          info: "Préparations des mariés à la pièce montée",
        },
        {
          info: "Rendu vidéo 4K stabilisé",
        },
        {
          info: "Montage de la vidéo et traitement de couleur inclus",
        },
        {
          info: "Durée allant de 18 à 25 minutes",
        },
        {
          info: "Rendu sous 21 jours",
        },
        {
          info: "Rendu sur clé USB emballé et envoyé directement chez vous (frais inclus dans la prestation)",
        },
        {
          info: "Ajout de plan de drone : 180€",
        },
         {
          info: "Ajout deuxième caméra : 120€",
        },
        {
          info: "Heures supplémentaires : 40€ / 1h",
        },
      ],
    },
    {
      title: "Photo + Vidéo",
      icon: "✨",
      price: "1550€ (TTC)",
      details: "",
      descr:
        "Il est également possible de prendre l'option de réalisation de photos et de vidéos !",
    },
  ],
};
