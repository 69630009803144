import React from "react";
import { useState } from "react";
import Seo from "../../components/Seo";
import HeaderShow from "../../components/HeaderShow/HeaderShow";
import "./style.css";
import { types, videos } from "../../data/videos";
import Filter from "../../components/designSystem/filter/Filter";

export default function Videos() {
  const [dataVideos, setDataVideos] = useState(videos);
  const [titlePage, setTitlePage] = useState("Services vidéos");
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Seo title="Mes vidéos" />
      <HeaderShow classname="videos">
        <span>Services&nbsp;</span>
        <span>vidéos</span>
      </HeaderShow>
      <main>
        <div className={loading ? "loading" : "loaded"}>
          <div className="loader"></div>
        </div>
        <Filter
          data={videos}
          setter={setDataVideos}
          setTitlePage={setTitlePage}
          loading={loading}
          setLoading={setLoading}
          type={types}
          animation={false}
        />
        <section className="centered-elements section-videos-container">
          {dataVideos.map((video, index) => {
            return (
              <div key={index} className="video-container">
                <iframe
                  src={`https://www.youtube.com/embed/${video.id}`}
                  title={video.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            );
          })}
        </section>
        {/* <motion.div
          initial={{ scaleX: 1.3 }}
          animate={{
            scaleX: 0,
            transition: { duration: 0.5, ease: "easeOut" },
          }}
          style={{ originX: 1 }}
          className="transition-screen"
        /> */}
      </main>
    </>
  );
}
