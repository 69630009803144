module.exports = {
  services: [
    {
      classname: "box-photos appear appear-3",
      title: "Photos",
      types: ["Événement", "Mariage", "Portrait", "Produit", "Culinaire"],
    },
    {
      classname: "box-videos appear appear-4",
      video: "https://www.youtube.com/embed/fyftU-oKibc",
      types: ["Événement", "Mariage", "Publicité", "Youtube"],
    },
  ],
};
