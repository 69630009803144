import React from "react";
import Chip from "../../designSystem/chip/Chip";
import Title from "../../designSystem/title/Title";
import "./style.css";
import { about } from "../../../data/about";

export default function About() {
  return (
    <section id="about" className="centered-elements anim-to-scroll">
      <div className="about-container">
        <div className="picture-container"></div>

        <div className="informations-container">
          <Chip title={"à propos"} />

          <div className="appear appear-1">
            <Title>
              <h3>
                Capturons les moments
                <br />
                <span className="golden-color">précieux</span> de votre
                <span className="golden-color"> mariage.</span>
              </h3>
            </Title>
          </div>
          {about.map((item, key) => {
            return (
              <p key={key} className="description appear appear-2">
                {item.descr}
              </p>
            );
          })}
        </div>
      </div>
    </section>
  );
}
