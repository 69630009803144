import React from "react";
import './style.css'

const Title = ({
    children,
    centered=''
}) => {
    return(
        
        <div className={`title${centered && ' centered'}`}>
            {children}
        </div>
    )
}

export default Title
