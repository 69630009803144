import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Scroll({ children, scrollToTop = true }) {
  const location = useLocation();

  useEffect(() => {
    if (!location.search || location.search === "?type=all") {
      window.scrollTo(0, 0); // Scroll to the top after route change if there is not params
    }
    

    // Animation of section which become visible
    window.addEventListener("scroll", () => {
      const scroll = window.scrollY;
      for (let elt of document.querySelectorAll(".anim-to-scroll")) {
        if (scroll > elt.offsetTop - window.innerHeight / 1.25) {
          elt.classList.add("active");
        } else elt.classList.remove("active");
      }
    });

    // Animation word by word
    let i = 0;
    for (let word of document.querySelectorAll(".big-title .word")) {
      word.style.animationDelay = i * 125 + "ms";
      i++;
    }
  }, [location]);

  return children;
}
