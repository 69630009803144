import React from "react";
import Seo from "../../components/Seo";
import HeaderShow from "../../components/HeaderShow/HeaderShow";
import Cta from "../../components/Sections/cta/Cta";
import "./style.css";
import { prestations } from "../../data/prestations";

export default function Pricing() {
  return (
    <>
      <Seo title="Prestations" />
      <HeaderShow>Mes tarifications</HeaderShow>

      <section className="centered-elements prestations">
        {prestations.map((item, key) => {
          return (
            <div key={key} className="prestations__container">
              <h2 className="prestations__title">{item.title}</h2>

              {item.price && (
                <h3 className="prestations__price">{item.price}</h3>
              )}

              <span className="prestations__icon">{item.icon}</span>

              {item.details && (
                <ul className="prestations__infos">
                  {item.details.map((detail, key) => {
                    return (
                      <li
                        className="prestations__infos-item"
                        style={detail.style}
                        key={key}
                      >
                        {detail.info}
                        {console.log(detail.marginTop)}
                      </li>
                    );
                  })}
                </ul>
              )}

              {item.descr && <p className="prestations__descr">{item.descr}</p>}
            </div>
          );
        })}
      </section>

      <Cta />
    </>
  );
}
