import React from "react";
import Seo from "../../components/Seo";
import Header from "../../components/Sections/header/Header";
import About from "../../components/Sections/about/About";
import Details from "../../components/Sections/details/Details";
import Services from "../../components/Sections/services/Services";
import Reviews from "../../components/Sections/reviews/Reviews";
import Cta from "../../components/Sections/cta/Cta";
import "./style.css";

export default function Home() {
  return (
    <>
      <Seo title="Photographe et Vidéaste" />
      <Header />
      <main className="main-home">
        <About />
        <Services />
        <Details/>
        {/* <Reviews/> */}
        <Cta border={true} background={true}/>
      </main>
    </>
  );
}
